<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4 noprint">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-theme', params: { accountId: this.$route.params.accountId } }">Themes</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ theme.label }}</h1>
                <p class="text-caption text-center">Theme overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="theme">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card>
                        <v-toolbar color="teal" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Color Theme
                        </v-toolbar-title>
                        <!-- <template v-if="isDraft">
                            <v-spacer/>
                            <v-btn color="white green--text" @click="publish">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']"/>
                                Publish
                            </v-btn>
                        </template> -->
                        <!-- <v-progress-linear
                            :active="emailDispatchStatus && emailDispatchStatus.status === 'started'"
                            :value="emailDispatchProgress"
                            absolute
                            bottom
                            color="green"
                        ></v-progress-linear> -->
                        </v-toolbar>
                        <v-card-text>

                    <p class="text-overline mb-0">Label</p>
                    <p class="mb-0 pb-0" v-if="!isEditingThemeLabel">
                        {{ theme.label }}
                        <v-btn icon color="green" @click="isEditingThemeLabel = true" class="noprint">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>
                    <v-form @submit.prevent="saveEditThemeLabel" v-if="isEditingThemeLabel">
                        <v-text-field
                            ref="themeLabelInput"
                            v-model="editThemeLabel"
                            label="Label"
                            :rules="newThemeLabelRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                        >
                            <template #append-outer>
                                <v-btn icon color="green" @click="saveEditThemeLabel">
                                    <font-awesome-icon :icon="['fas', 'check']"/>
                                </v-btn>
                                <v-btn icon color="grey" @click="isEditingThemeLabel = false">
                                    <font-awesome-icon :icon="['fas', 'times']"/>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-form>

                    <p class="text-overline mb-0 mt-8">Mode</p>
                    <p class="mb-0 pb-0" v-if="!isEditingThemeMode">
                        {{ themeMode }}
                        <v-btn icon color="green" @click="isEditingThemeMode = true" class="noprint">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>
                    <v-form v-if="isEditingThemeMode">
                        <v-select :items="modeChoices" v-model="editThemeMode">
                            <template #append-outer>
                                <v-btn icon color="green" @click="saveEditThemeMode">
                                    <font-awesome-icon :icon="['fas', 'check']"/>
                                </v-btn>
                                <v-btn icon color="grey" @click="isEditingThemeMode = false">
                                    <font-awesome-icon :icon="['fas', 'times']"/>
                                </v-btn>
                            </template>
                        </v-select>
                    </v-form>

                    <!-- <p class="text-overline mb-0 mt-8">Media Type</p>
                    <p class="mb-0 pb-0" v-if="!isEditingThemeMediaType">
                        {{ themeMediaType }}
                        <v-btn icon color="green" @click="isEditingThemeMediaType = true" class="noprint">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>
                    <v-form v-if="isEditingThemeMediaType">
                        <v-select :items="mediaTypeChoices" v-model="editThemeMediaType">
                            <template #append-outer>
                                <v-btn icon color="green" @click="saveEditThemeMediaType">
                                    <font-awesome-icon :icon="['fas', 'check']"/>
                                </v-btn>
                                <v-btn icon color="grey" @click="isEditingThemeMediaType = false">
                                    <font-awesome-icon :icon="['fas', 'times']"/>
                                </v-btn>
                            </template>
                        </v-select>
                    </v-form> -->

                    <!-- TODO: logo, screenshots, other images -->
                    <!-- <p class="text-overline mb-0 mt-8">Images</p>
                    <p class="text-caption mb-0 mt-0">Logos, screenshots, etc.</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-image', params: { accountId: this.$route.params.accountId }, query: { brandId: this.$route.params.brandId } }">See all images</router-link>
                    </p> -->

                    <p class="text-overline mb-0 mt-8">Colors and Palettes</p>
                    <p class="text-caption mb-0 mt-0">Color definitions and combinations</p>
                    <!-- <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-search-color', params: { accountId: this.$route.params.accountId }, query: { brandId: this.$route.params.brandId } }">See all colors</router-link>
                    </p> -->
                    <p>
                        <router-link :to="{ name: 'account-search-palette', params: { accountId: this.$route.params.accountId }, query: { brandId: this.$route.params.brandId } }">See all palettes</router-link>
                    </p>

                        </v-card-text>

                        <!-- <ThemeColorList :themeId="theme.id" role="primary" :content="theme.content" @edit="saveEditThemeColorList"/>
                        <ThemeColorList :themeId="theme.id" role="secondary" :content="theme.content" @edit="saveEditThemeColorList"/>
                        <ThemeColorList :themeId="theme.id" role="accent" :content="theme.content" @edit="saveEditThemeColorList"/>
                        <ThemeColorList :themeId="theme.id" role="background" :content="theme.content" @edit="saveEditThemeColorList"/> -->

                    </v-card>

                    <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                    <p class="mb-0 pb-0">
                        {{ theme.comment }}
                        <v-btn icon color="green" @click="editThemeComment">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p> -->

                    <!-- <p class="text-overline mb-0 mt-8">Theme ID</p>
                    <p class="mb-0 pb-0">
                        {{ theme.id }}
                    </p> -->

                    <!-- TODO: show the usages of the theme, such as which themes, which images, etc. -->
                    <!-- <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailContactList.length > 0">
                            This theme is subscribed by {{ affectedEmailContactList.length }} email contacts.
                            < ! - - TODO: show the affectedEmailContactList - - >
                        </span>
                        <span v-if="affectedEmailContactList.length === 0">
                        This theme is not currently applied to any email contacts.
                        </span>
                    </p> -->

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, themeId: this.$route.params.themeId } }">Access control</router-link>
                    </p> -->

                    <v-expansion-panels class="mt-8 noprint" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Theme Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.theme, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editThemeLabelDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the theme label</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The theme label can be technically descriptive, such as "light blue", or it can be more emotionally descriptive, like "sunrise sky".
                            You can change it at any time. The theme label is PUBLIC.
                        </p>

                        <v-text-field
                            ref="themeLabelInput"
                            v-model="editThemeLabel"
                            label="Label"
                            :rules="newThemeLabelRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditThemeLabel" :disabled="!isEditThemeLabelComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editThemeLabelDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <!-- <v-dialog v-model="editThemeCommentDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the theme comment</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The theme comment is a place where you can add more information for your own reference about how you use the theme.
                            You can change it at any time. The comment is PRIVATE. It is NOT shown to users.
                        </p>
                        <v-textarea v-model="editableThemeComment" label="Theme comment"></v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditThemeComment" :disabled="!isEditThemeCommentComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editThemeCommentDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog> -->
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
// import ThemeColorList from '@/components/account-dashboard/ThemeColorList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // ThemeColorList,
    },
    data: () => ({
        // affectedEmailContactList: [],

        error: null,
        account: null,
        theme: null,
        invite: null,
        isDraft: null,

        // edit form title dialog
        editThemeLabelDialog: false,
        editThemeCommentDialog: false,
        editableThemeComment: null,

        // edit theme label in place
        isEditingThemeLabel: false,
        editThemeLabel: null,

        // edit theme mode in place
        isEditingThemeMode: false,
        editThemeMode: null,
        modeChoices: [
            { text: 'Light Mode', value: 'light' },
            { text: 'Dark Mode', value: 'dark' },
        ],

        // edit theme media type in place
        isEditingThemeMediaType: false,
        editThemeMediaType: null,
        mediaTypeChoices: [
            { text: 'All', value: 'all' },
            { text: 'Print', value: 'print' },
            { text: 'Screen', value: 'screen' },
        ],
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.form !== null;
        },
        isEditThemeLabelComplete() {
            return typeof this.editThemeLabel === 'string' && this.editThemeLabel.trim().length > 0;
        },
        isEditThemeCommentComplete() {
            return typeof this.editableThemeComment === 'string' && this.editableThemeComment.trim().length > 0;
        },
        themeMode() {
            return this.modeChoices.find((item) => item.value === this.theme.mode)?.text;
        },
        themeMediaType() {
            return this.mediaTypeChoices.find((item) => item.value === this.theme.media_type)?.text;
        },
    },
    watch: {
        isEditingThemeLabel(newValue) {
            if (newValue) {
                this.editThemeLabel = this.theme.label;
                setTimeout(() => { this.$nextTick(() => this.$refs.themeLabelInput.focus()); }, 1);
            }
        },
        isEditingThemeMode(newValue) {
            if (newValue) {
                this.editThemeMode = this.theme.mode;
            }
        },
        isEditingThemeMediaType(newValue) {
            if (newValue) {
                this.editThemeMediaType = this.theme.media_type;
            }
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadTheme() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadTheme: true });
                const response = await this.$client.account(this.$route.params.accountId).theme.get({ id: this.$route.params.themeId });
                console.log(`loadTheme: response ${JSON.stringify(response)}`);
                if (response) {
                    this.theme = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.comment ?? '';
                    // this.theme.comment ??= '';
                    // this.theme.reply_to ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load theme');
                }
            } catch (err) {
                console.error('failed to load theme', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadTheme: false });
            }
        },
        async checkIsDraft() {
            try {
                this.$store.commit('loading', { checkIsDraft: true });
                const response = await this.$client.account(this.$route.params.accountId).theme.check({ id: this.$route.params.themeId, item: 'draft' });
                console.log(`checkIsDraft: response ${JSON.stringify(response)}`);
                this.isDraft = response?.isDraft;
            } catch (err) {
                console.error('failed to load theme', err);
                this.isDraft = null;
            } finally {
                this.$store.commit('loading', { checkIsDraft: false });
            }
        },
        async save(themeAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditTheme: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).theme.edit({ id: this.$route.params.themeId }, themeAttr);
                console.log(`saveEditTheme: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit theme' });
                return false;
            } catch (err) {
                console.error('failed to edit theme', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit theme' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditTheme: false });
            }
        },
        editThemeLabel() {
            this.editThemeLabelDialog = true;
            this.editThemeLabel = this.theme.label;
        },
        editThemeComment() {
            this.editThemeCommentDialog = true;
            this.editableThemeComment = this.theme.comment;
        },
        async saveEditThemeLabel() {
            const isEdited = await this.save({ label: this.editThemeLabel });
            // this.editThemeLabelDialog = false;
            this.isEditingThemeLabel = false;
            if (isEdited) {
                this.$set(this.theme, 'label', this.editThemeLabel);
            }
        },
        async saveEditThemeMode() {
            const isEdited = await this.save({ mode: this.editThemeMode });
            this.isEditingThemeMode = false;
            if (isEdited) {
                this.$set(this.theme, 'mode', this.editThemeMode);
            }
        },
        async saveEditThemeMediaType() {
            const isEdited = await this.save({ media_type: this.editThemeMediaType });
            this.isEditingThemeMediaType = false;
            if (isEdited) {
                this.$set(this.theme, 'media_type', this.editThemeMediaType);
            }
        },
        async saveEditThemeComment() {
            const isEdited = await this.save({ comment: this.editableThemeComment });
            this.editThemeCommentDialog = false;
            if (isEdited) {
                this.$set(this.theme, 'comment', this.editableThemeComment);
            }
        },
        async saveEditThemeColorList({ themeId, role, list }) {
            console.log(`saveEditThemeColorList for theme id ${themeId} role ${role} list ${JSON.stringify(list)}`);
            const content = this.theme.content ?? {};
            content[role] = list;
            const isEdited = await this.save({ content });
            // this.editThemeCommentDialog = false;
            if (isEdited) {
                this.$set(this.theme, 'content', content);
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadTheme();
        this.checkIsDraft();
    },
};
</script>
